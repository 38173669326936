import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loader from 'Componentes/Loader'
import ScrollToTop from './ScrollToTop'
import Authenticated from 'Componentes/Authenticated'
import Layout from 'Layout'


const Erro404 = lazy(() => import('Pages/Erro404'))
const Home = lazy(() => import('Pages/Home'))
const Login = lazy(() => import('Pages/Login'))
const Pedido = lazy(() => import('Pages/Pedido/index'))
const PedidoDetalhe = lazy(() => import('Pages/Pedido/detalhe'))
const Produto = lazy(() => import('Pages/Produto/index'))
const ProdutoEdit = lazy(() => import('Pages/Produto/edit'))
const EsqueciMinhaSenhaEdit = lazy(() => import('Pages/EsqueciMinhaSenha'))
const Usuario = lazy(() => import('Pages/Usuario/index'))
const UsuarioEdit = lazy(() => import('Pages/Usuario/edit'))
const Cadastro = lazy(() => import('Pages/Cadastro/index'))
const CadastroEdit = lazy(() => import('Pages/Cadastro/edit'))

const Beneficio = lazy(() => import('Pages/Beneficio/index'))
const BeneficioEdit = lazy(() => import('Pages/Beneficio/edit'))

const Configuracao = lazy(() => import('Pages/Configuracao/index'))
const Frete = lazy(() => import('Pages/Frete/index'))
const FreteEdit = lazy(() => import('Pages/Frete/edit'))
const Marca = lazy(() => import('Pages/Marca/index'))
const MarcaEdit = lazy(() => import('Pages/Marca/edit'))
const Relatorio = lazy(() => import('Pages/Relatorio'))

export default () => (
  <Router>
    <Authenticated>
      <Suspense fallback={<Loader />}>
        <Layout>
          <ScrollToTop>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/login/alterar" component={Login} />
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/alterar-senha" component={EsqueciMinhaSenhaEdit} />

              <Route exact path="/configuracao" component={Configuracao} />
              
              <Route exact path="/frete" component={Frete} />
              <Route exact path="/frete-edit" component={FreteEdit} />
              <Route exact path="/frete-edit/:freteId" component={FreteEdit} />
     
              <Route exact path="/marca" component={Marca} />
              <Route exact path="/marca-edit" component={MarcaEdit} />
              <Route exact path="/marca-edit/:marcaId" component={MarcaEdit} />

              <Route exact path="/cadastro" component={Cadastro} />
              <Route exact path="/cadastro-edit" component={CadastroEdit} />
              <Route exact path="/cadastro-edit/:cadastroId" component={CadastroEdit} />
              
              <Route exact path="/pedido" component={Pedido} />
              <Route exact path="/pedido-detalhe/:numeroPedido" component={PedidoDetalhe} />

              <Route exact path="/produto" component={Produto} />
              <Route exact path="/produto-edit" component={ProdutoEdit} />
              <Route exact path="/produto-edit/:produtoId" component={ProdutoEdit} />

              <Route exact path="/usuario" component={Usuario} />
              <Route exact path="/usuario-edit" component={UsuarioEdit} />
              <Route exact path="/usuario-edit/:usuarioId" component={UsuarioEdit} />

              <Route exact path="/beneficio" component={Beneficio} />
              <Route exact path="/beneficio-edit" component={BeneficioEdit} />
              <Route exact path="/beneficio-edit/:beneficioId" component={BeneficioEdit} />

              <Route exact path="/relatorio" component={Relatorio} />

              <Route exact path="/404" component={Erro404} />
              <Route path="*" component={Erro404} />
            </Switch>
          </ScrollToTop>
        </Layout>
      </Suspense>
    </Authenticated>
  </Router>
)
