import React from 'react'
import { withRouter } from 'react-router-dom'

import MenuLateral from 'Componentes/MenuLateral'
import Cabecalho from 'Componentes/Header'
import dotenv from 'dotenv';
dotenv.config();

const routesWithoutMenu = ['/login']


function isRouteWithoutMenu(route) {
  const isHideMenu = routesWithoutMenu.some((el) => route.indexOf(el) !== -1)
  return isHideMenu
}

function Layout({ children, location }) {
  if (isRouteWithoutMenu(location.pathname)) {
    return children
  }

  return (
    <div id="outer-container">
      {/* {process.env.AMBIENTE !== 'production' && (
        <div style={{ height: 30, width: '100%', backgroundColor: '#ffdc01', textAlign: 'center' }}>
          Ambiente de Testes e Homologação
        </div>
      )} */}

      <MenuLateral />
      <main className="containerLayoutMaster" id="page-wrap">
        <div className="contentMain">
          <Cabecalho />
          {children}
        </div>
      </main>
    </div>
  )
}

export default withRouter(Layout)
