import Axios from "axios";

var slugify = require('slugify')
export const StringSlugify = (stringText) => {
	return slugify(stringText, { lower: true })
}

export const FormValidation = (form, required) => {
	return Object.keys(required).filter(item => {
		if (Object.keys(form).includes(item)) {
			if (form[item] === "" || form[item] === undefined || form[item] === null) {
				return { error: true, field: item };
			}
		} else {
			return { error: true, field: item };
		}
	})
}

export const CheckHasErrorInput = (errorsForm, key) => {
	return errorsForm && (errorsForm.includes(key) ? "inputError" : "")
}

export const FormattingNumberWithoutDecimals = (money) => {
	return parseInt(money).toLocaleString("pt-BR", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
}

export const FormattingNumberWithDecimals = (money) => {
	return parseFloat(money).toLocaleString("pt-BR", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
}

export const SetKey = (key, value) => {
	localStorage.setItem(key, value)
}

export const GetKey = (key) => {
	return localStorage.getItem(key)
}

export const DeleteKey = (key) => {
	localStorage.removeItem(key);
}

export const ClearStorage = () => {
	localStorage.clear();
}

export const RemoveCaracteresValor = (value) => {
	var valor = value.toString()
	valor = valor.replace('R$', '');
	valor = valor.replace(',', '.')

	if (valor.split('.').length > 2) {
		valor = valor.replace(/\./, '')
	}

	return valor;
}

export const RestoreFilters = (location) => {
	const { pathname } = location
	const filters = JSON.parse(GetKey("form-search"))
	if (filters) {
		if (pathname.split("/")[1] === filters.page) {
			const filtroPage = { ...filters }
			delete filtroPage["page"]
			return filtroPage
		} else {
			localStorage.removeItem("form-search");
		}
	}
}

export function CheckCnpj(cnpj) {

	cnpj = cnpj.replace(/[^\d]+/g, '');

	if (cnpj == '') return false;

	if (cnpj.length != 14)
		return false;

	// Elimina CNPJs invalidos conhecidos
	if (cnpj == "00000000000000" ||
		cnpj == "11111111111111" ||
		cnpj == "22222222222222" ||
		cnpj == "33333333333333" ||
		cnpj == "44444444444444" ||
		cnpj == "55555555555555" ||
		cnpj == "66666666666666" ||
		cnpj == "77777777777777" ||
		cnpj == "88888888888888" ||
		cnpj == "99999999999999")
		return false;

	// Valida DVs
	var tamanho = cnpj.length - 2
	var numeros = cnpj.substring(0, tamanho);
	var digitos = cnpj.substring(tamanho);
	var soma = 0;
	var pos = tamanho - 7;
	for (var i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(0))
		return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(1))
		return false;

	return true;
}

export function CheckDocumento(strCPF) {
	if (!strCPF) return
	var Soma;
	var Resto;
	Soma = 0;

	var documento = strCPF.replace(/[\W_]/gi, '')

	if (documento.split('').every(char => char === documento[0])) return false;

	for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(documento.substring(i - 1, i)) * (11 - i);
	Resto = (Soma * 10) % 11;

	if ((Resto === 10) || (Resto === 11)) Resto = 0;
	if (Resto !== parseInt(documento.substring(9, 10))) return false;

	Soma = 0;
	for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(documento.substring(i - 1, i)) * (12 - i);
	Resto = (Soma * 10) % 11;

	if ((Resto === 10) || (Resto === 11)) Resto = 0;
	if (Resto !== parseInt(documento.substring(10, 11))) return false;
	return true;
}

export function CheckCEP(value) {
	return Axios.get(`https://viacep.com.br/ws/${value}/json`)
}

export const ValidadeStringSize = (string, maxLength) => {
	if (string.length <= maxLength) {
		return string;
	} else {
		return string.slice(0, maxLength) + "..."
	}
}

export function IsEmail(email) {
	const RegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	if (RegEx.test(email)) {
		return true;
	} else {
		return false;
	}
}

export function IsValidData(d) {
	if (!d) return false;
	var date = new Date(d);
	return date instanceof Date && !isNaN(date);
}

export const PluginTabs = () => {
	const options = { duration: 300, onShow: null, responsiveThreshold: Infinity, swipeable: false }
	var elems = document.querySelectorAll('.tabs');
	var instance = window.M.Tabs.init(elems, options);
}

export const Estados = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"] 
