import React, { useEffect, useState, Text } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { reveal as Menu } from 'react-burger-menu'
import 'Style/Layout.scss'
import Logo from '../../Assets/icons/logo.png'
import dotenv from 'dotenv';
dotenv.config();

function MenuLateral({ history }) {
  const [active, setActive] = useState(false)

  const listaMenus = [
    { icone: 'home', link: '/home', nome: 'Home' },
    { icone: 'person', link: '/cadastro', nome: 'Cadastro' },
    { icone: 'assignment', link: '/pedido', nome: 'Pedido' },
    { icone: 'redeem', link: '/beneficio', nome: 'Benefício' },
    { icone: 'qr_code', link: '/produto', nome: 'Produto' },
    { icone: 'group', link: '/usuario', nome: 'Usuário' },
    { icone: 'local_shipping', link: '/frete', nome: 'Frete' },
    { icone: 'business', link: '/marca', nome: 'Marca' },
    { icone: 'settings', link: '/configuracao', nome: 'Configurações' },
    // { icone: 'dashboard', link: '/relatorio', nome: 'Relatorio' },
  ]

  useEffect(() => {
    const unlisten = history.listen(() => {
      setActive(false)
    })
    return unlisten
  }, [history])

  const HandlerChange = e => {
    setActive(e.isOpen)
  }

  return (
    <Menu
      left
      pageWrapId={'page-wrap'}
      outerContainerId={'outer-container'}
      isOpen={active}
      onStateChange={HandlerChange}
    >
      <div className="logo">
        <Link to='/' >
          <img src={Logo} alt="" />
        </Link>
      </div>

      <div className="SideMenu">

        {listaMenus && listaMenus.map((menu, index) => {
          return (
            <Link to={menu.link} key={index} >
              <div className="item-static" key={index}>
                <i className="material-icons">{menu.icone}</i>{menu.nome}
              </div>
            </Link>
          )
        })}
      </div>
      
    </Menu>
  )
}
export default withRouter(MenuLateral)
