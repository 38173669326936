import React, { Component } from "react";
import Router from "./Router"

import { ToastContainer, toast } from 'react-toastify'
import { QueryClientProvider, QueryClient } from "react-query";
import 'react-toastify/dist/ReactToastify.css'
import './App.scss';

const queryClient = new QueryClient();

class App extends Component {
    render() {

        return (
            <QueryClientProvider client={queryClient}>

                <ToastContainer enableMultiContainer position={toast.POSITION.TOP_RIGHT} />
                <Router />
            </QueryClientProvider>
        )
    }
}

export default App;