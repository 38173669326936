import React, { useState, useEffect, lazy } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { GetKey, SetKey } from 'Utils'

const Authenticated = ({ children, location }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(GetKey('token'))
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    const token = GetKey('token')
    if (!token) {
      if (
        location.pathname.includes("login")
      ) {
        setRedirect(false)
      } else {
        if (location.pathname !== "/") {
          SetKey("historyUrl", location.pathname)
        }
        setRedirect(true)
      }
    } else {
      setIsAuthenticated(token)
    }

  }, [location])

  if (isAuthenticated === undefined || isAuthenticated === null) {
    return (
      <>
        {children}
        {redirect && <Redirect to='/login' />}
      </>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default withRouter(Authenticated)