import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ClearStorage, GetKey } from 'Utils';
import "Style/Layout.scss";
import ciko from '../../Assets/ciko.png';

export default function Cabecalho() {
  const [user, setUser] = useState(GetKey('usuario'))

  const HandlerLogout = (e) => {
    // e.preventDefault()
    ClearStorage();
  }

  return (
    <div className="navMainTop">
      <ul className="user">
        <li><i className="material-icons">face</i> {user} | <span>Administrador</span></li>
      </ul>
      <div className='logo-top'>
        <img src={ciko}/>
      </div>
      <div className="rightCol">
        <div className="help-desk" data-tip="Alterar minha senha">
          <Link to="/alterar-senha">
            <span className="material-icons">
              vpn_key
            </span>
          </Link>
        </div>
        <Link to="/login" onClick={HandlerLogout} className="logout">
          Sair <i className="material-icons">keyboard_tab</i>
        </Link>
      </div>
      <ReactTooltip />
    </div >
  )
}
